import revive_payload_client_1T7olHNdxf from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3xmwLharIt from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tUCDmcSZoF from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_X3ua4g6tf8 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UCnmpaQZvW from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FJADCA8Xag from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yu46e4xCD8 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Z7CLPOlEsx from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/components.plugin.mjs";
import prefetch_client_eBhnBiTQnr from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ymuh5xbrsei6axpezhw763pmhu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_SAlze24dE1 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_eIjHw3XW7D from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_rollup@4.24.0_vue@3.5.11/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import templates_plugin_2109b242_p2wa7e3c1X from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/templates.plugin.2109b242.mts";
import wow_RYu4ocOzj5 from "/home/runner/work/hopeai-official/hopeai-official/plugins/wow.ts";
export default [
  revive_payload_client_1T7olHNdxf,
  unhead_3xmwLharIt,
  router_tUCDmcSZoF,
  payload_client_X3ua4g6tf8,
  navigation_repaint_client_UCnmpaQZvW,
  check_outdated_build_client_FJADCA8Xag,
  chunk_reload_client_yu46e4xCD8,
  plugin_vue3_Z7CLPOlEsx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eBhnBiTQnr,
  plugin_client_SAlze24dE1,
  plugin_eIjHw3XW7D,
  templates_plugin_2109b242_p2wa7e3c1X,
  wow_RYu4ocOzj5
]