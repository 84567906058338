import { default as companyKmOl8cC36qMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/company.vue?macro=true";
import { default as indexJxpYUai3MLMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/index.vue?macro=true";
import { default as PUREEvidenceg2U8keslqCMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/PUREEvidence.vue?macro=true";
import { default as research0ZZyxKSmCcMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/research.vue?macro=true";
import { default as synthIPDnhUsBaCsGXMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue?macro=true";
export default [
  {
    name: "company",
    path: "/company",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/company.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/index.vue")
  },
  {
    name: "PUREEvidence",
    path: "/PUREEvidence",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/PUREEvidence.vue")
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/research.vue")
  },
  {
    name: "synthIPD",
    path: "/synthIPD",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue")
  }
]